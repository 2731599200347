import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * Pads a number to two digits.
 *
 * @param value The value to pad.
 * @returns The padded value.
 */
const padNumber = (value: number): string => (isNumber(value)) ? `0${value}`.slice(-2) : '';

/**
 * Determines whether a the integer representation of a value is a number.
 *
 * @param value The value.
 * @returns A value indicating whether the integer representation of value is a number.
 */
const isNumber = (value: any): boolean => !isNaN(toInteger(value));


/**
 * Convert to an integer.
 *
 * @param value The value to convert.
 * @returns The integer representation of value.
 */
const toInteger = (value: any): number => parseInt(`${value}`, 10);

/**
 * Convert to an integer that is a valid year.
 *
 * @param value The value to convert.
 * @returns The integer representation of value.
 */
const toYear = (value: string): number => {
  let result = parseInt(`${value}`, 10);

  if (!isNaN(result) && value.length === 2) {
    const currentYear = new Date(Date.now()).getUTCFullYear().toString().slice(-2);

    if (result > +currentYear) {
      result += 1900;
    } else {
      result += 2000;
    }
  }

  return result;
};

/**
 * Convert month name that is a valid month to an integer.
 *
 * @param value The value to convert.
 * @returns The month number between 1 and 12 or 0 if the month name cannot be parsed.
 */
const toMonth = (value: string): number => {
  const testDate = Date.parse(`${value} 1, 2019`);
  return isNaN(testDate) ? 0 : new Date(testDate).getMonth() + 1;
};

/**
 * A date parser and formatter for date input, shamelessly plagerised
 * from an example on the Ngb GitHub project and changed to allow input
 * dates to be seperated by '/', '.' or '-'.
 */
@Injectable()
export class DateParserFormatter extends NgbDateParserFormatter {

  /**
   * Parse a date string.
   *
   * @param value The date string to parse.
   * @returns The NgbDateStruct representing the parsed date.
   */
  public parse(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value) {
      const longDateMatch = value.match(/[A-Za-z]* *(?<day>[0-9]{1,2}) *(?<month>[A-Za-z]{3,}) *(?<year>[0-9]{1,4})/);
      if (longDateMatch && toMonth(longDateMatch.groups.month)) {
        result = {
          year: toYear(longDateMatch.groups.year),
          month: toMonth(longDateMatch.groups.month),
          day: toInteger(longDateMatch.groups.day)
        };
      } else {
        const dateParts = value.replace(/[.-]/g, '/').trim().split('/');
        if (dateParts.length === 1 && isNumber(dateParts[0])) {
          result = { year: toYear(dateParts[0]), month: null, day: null };
        } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
          result = { year: toYear(dateParts[1]), month: toInteger(dateParts[0]), day: null };
        } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
          result = { year: toYear(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
        }
      }
    }
    return result;
  }

  /**
   * Formats a date string.
   *
   * @param date The NgbSateStruct representing the date.
   * @returns The formatted date.
   */
  public format(date: NgbDateStruct): string {
    let result = '';
    if (date) {
      result = (isNumber(date.day) ? padNumber(date.day) + '/' : '') +
        (isNumber(date.month) ? padNumber(date.month) + '/' : '') +
        date.year;
    }
    return result;
  }
}
