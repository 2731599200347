import { Injectable } from '@angular/core';

import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import { lastValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';

import { AuthService } from '../authentication/auth.service';

/**
 * A factory for generating WebSocket server connections.
 */
@Injectable({
  providedIn: 'root'
})
export class ConnectionFactoryService {

  /**
   * Creates an instance of connection factory service.
   *
   * @param authService The {@link AuthService} instance to use.
   */
  constructor(private readonly authService: AuthService) { }

  /**
   * Get a new WebSocket server connection.
   *
   * @returns The new connection.
   */
  public getHubConnection(): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(environment.chatterUri, {
        accessTokenFactory: () => lastValueFrom(this.authService.getAccessTokenForEndpoint(environment.chatterUri)),
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .configureLogging(LogLevel.Information)
      .build();
  }
}
