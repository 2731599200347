import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastManagerService } from 'src/app/shared';

/**
 * An HTTP Interceptor to inform the user of HTTP errors.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  /**
   * Creates an instance of HTTP error interceptor.
   *
   * @param toastManagerService The {@link ToastManagerService} to use.
   */
  constructor(private readonly toastManagerService: ToastManagerService) { }

  /**
   * Intercept any HTTP error, report it to the user and then rethrow it to allow further error handling.
   *
   * @param req The outgoing request object to handle.
   * @param next The next interceptor in the chain, or the backend if no interceptors remain in the chain.
   * @returns Intercept an observable of the event stream.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          if (error?.error?.message?.match(/Could not find linked search ID.*linked to appointment ID/i) && error.status === 404) {
            return of(null);
          }

          this.toastManagerService.show({
            header: 'API Error',
            body: this.formatMessage(error)
          });
          throw error;
        } else {
          throw error;
        }
      }));
  }

  /**
   * Formats a suitable message based on the error content.
   *
   * @param error The error to process.
   * @returns Message The formatted message.
   */
  private formatMessage(error: HttpErrorResponse): string {
    let result: string;
    switch (error.status) {
      case 401:
      case 403:
        result = 'The current user is not authorised to perform the requested operation';
        break;
      case 404:
        result = 'The requested object could not be found';
        break;
      default:
        result = `An HTTP error with a status of ${error.status} was received.`;
        break;
    }

    return result;
  }
}
