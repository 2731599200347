import { Injectable } from '@angular/core';
import { Toast } from './toast';

/**
 * A service to manage messages to be displayed to the user as toasts.
 */
export interface IToastManagerService {

  /**
   * The list of currently active toasts.
   */
  readonly toasts: Toast[];

  /**
   * Adds a toast to the list of active toasts.
   *
   * @param toast The toast to add.
   */
  show(toast: Toast): void;

  /**
   * Removes a toast from the active list.
   *
   * @param toast The toast to remove.
   */
  remove(toast: Toast): void;
}

/**
 * An implementation of {@link IToastManagerService}.
 */
@Injectable({
  providedIn: 'root'
})
export class ToastManagerService implements IToastManagerService {
  /**
   * The list of currently active toasts.
   */
  public readonly toasts: Toast[] = [];

  /**
   * Adds a toast to the list of active toasts.
   *
   * @param toast The toast to add.
   */
  public show(toast: Toast): void {
    this.toasts.push(toast);
  }

  /**
   * Removes a toast from the active list.
   *
   * @param toast The toast to remove.
   */
  public remove(toast: Toast): void {
    if (this.toasts.indexOf(toast) > -1) {
      this.toasts.splice(this.toasts.indexOf(toast), 1);
    }
  }
}
