/**
 * Valid message types for the Chatter service.
 */
export enum ChatterMessageType {
  getConnectionId = 'getConnectionId',
  objectChanged = 'ObjectChanged',
  objectCollectionChanged = 'ObjectCollectionChanged',
  searchOpened = 'SearchOpened',
  searchClosed = 'SearchClosed',
  searchUpdated = 'SearchUpdated',
  searchCreated = 'SearchCreated',
  hartsMessage = 'HartsMessage'
}
