import { Injectable } from '@angular/core';

import { NEVER, Observable } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { ApiConverter } from 'src/app/api';
import { DataStoreService } from 'src/app/data-store';

import { ApiAuthority, ApiDepartment } from '../api-model';
import { ApiService } from '../api/services';
import { ApiLocalAuthorityGroup, AuthorityDetails, Department } from './model';


/**
 * A service to support data access for Local Authority Data.
 */
export interface ILocalAuthorityService {

  /**
   * A stream of the currently available Local Authority Areas.
   */
  readonly localAuthorities$: Observable<AuthorityDetails[]>;

  /**
   * A stream of the currently available Local Authority Areas.
   */
  readonly localAuthorityGroups$: Observable<ApiLocalAuthorityGroup[]>;
}

/**
 * A service to support data access for Local Authority Data.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalAuthorityService implements ILocalAuthorityService {

  /**
   * A stream of the currently available Local Authority Areas.
   */
  public readonly localAuthorityGroups$ = this.apiService.api$.searchGroups.pipe(
    switchMap(x => this.dataStoreService.loadCollection<ApiLocalAuthorityGroup>(x)),
    switchMap(areas => ApiConverter.loadStoreObjectsData(areas.data, this.dataStoreService)),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * Local authorities$ of local authority service.
   */
  public readonly localAuthorities$ = this.apiService.api$.authorities.pipe(
    switchMap(x => this.dataStoreService.loadCollection<ApiAuthority>(x)),
    switchMap(authorities => ApiConverter.loadStoreObjectsData(authorities.data, this.dataStoreService)),
    catchError(() => NEVER),
    map(apiAuthorities => apiAuthorities.map(a => ApiConverter.convertApiAuthority(a, this.dataStoreService))),
    shareReplay({ refCount: false, bufferSize: 1 })
  );


  /**
   * Gets all departments.
   *
   * @param departmentsHref The URI to get the departments from.
   * @returns All departments.
   */
  public getAllDepartments(departmentsHref: string): Observable<Department[]> {
    return this.dataStoreService.loadCollection<ApiDepartment>(departmentsHref).pipe(
      switchMap(departments => ApiConverter.loadStoreCollectionData(departments, this.dataStoreService)),
      map(apiDepartments => apiDepartments.map(d => ApiConverter.convertApiDepartment(d, this.dataStoreService))),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * Creates an instance of local authority service.
   *
   * @param dataStoreService The {@link DataStoreService} instance to use.
   * @param apiService The {@link ApiService} instance to use.
   */
  constructor(
    private readonly dataStoreService: DataStoreService,
    private readonly apiService: ApiService
  ) { }
}
