import { Component } from '@angular/core';

/**
 * The Application Root Component.
 */
@Component({
  selector: 'fw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent { }
