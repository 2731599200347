import { Injectable } from '@angular/core';

/**
 * A list of the optional properties of an email.
 */
const emailOptionNames = [
  'subject',
  'cc',
  'bcc',
  'body'
] as const;

/**
 * The type definition for optional email properties taken from
 * the list of valid email properties.
 */
export type EmailOptions = { [key in typeof emailOptionNames[number]]?: string };

/**
 * A service to implement email functionality.
 */
export interface IEmailService {
  /**
   * Opens the default email client with an addressed template email.
   *
   * @param to The email address to send to.
   * @param [options] The additional email headers and body to set in the template.
   */
  createEmail(to: string, options?: EmailOptions): void;
}

/**
 * A service to implement email functionality.
 */
@Injectable({
  providedIn: 'root'
})
export class EmailService implements IEmailService {

  /**
   * Opens the default email client with an addressed template email.
   *
   * @param to The email address to send to.
   * @param [options] The additional email headers and body to set in the template.
   */
  public createEmail(
    to: string,
    options?: EmailOptions
  ): void {
    const parameters = options ?
      emailOptionNames.map(
        key => [key, options[key]]
      ).filter(
        ([key, value]) => value !== undefined
      ).map(
        ([key, value]) => `${key}=${encodeURIComponent(value)}`
      ).join('&') : '';

    const windowRef = window.open(`mailto:${encodeURI(to)}${parameters.length > 0 ? '?' + parameters : ''}`);

    windowRef.focus();

    setTimeout(() => {
      if (!(windowRef.document && windowRef.document.hasFocus())) {
        windowRef.close();
      }
    }, 1000);
  }
}
