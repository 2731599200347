import { Injectable } from '@angular/core';
import { MsalGuardAuthRequest, MsalService } from '@azure/msal-angular';
import { catchError, map, Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';

/**
 * Service that provides access to the current user data obtained from authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * Creates an instance of auth service.
   *
   * @param msalService The MSAL Service instance to use.
   */
  constructor(private readonly msalService: MsalService) { }

  /**
   * Gets an access token for the specified endpoint.
   *
   * @param endpoint Endpoint to get access token for.
   * @returns A pipeline that will emit the access token.
   */
  public getAccessTokenForEndpoint(endpoint: string): Observable<string> {
    return this.msalService.acquireTokenSilent({
      account: this.msalService.instance.getActiveAccount() ?? this.msalService.instance.getAllAccounts()[0],
      scopes: (environment.msalGuardConfig.authRequest as MsalGuardAuthRequest).scopes
    }).pipe(
      map(x => x.accessToken),
      catchError((err: unknown) => of(null))
    );
  }
}
