import { Component } from '@angular/core';

import { Toast } from './toast';
import { ToastManagerService } from './toast-manager.service';

/**
 * A component to display messages to the user in the form of toasts.
 */
@Component({
  selector: 'fw-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  /**
   * Creates an instance of toast component.
   *
   * @param toastManagerService The {@link ToastManagerService} instance to use.
   */
  constructor(private readonly toastManagerService: ToastManagerService) { }

  /**
   * Gets the currently active toasts.
   */
  public get toasts(): Toast[] {
    return this.toastManagerService.toasts;
  }

  /**
   * Hides a toast.
   *
   * @param toast The toast to hide.
   */
  public hide(toast: Toast): void {
    this.toastManagerService.remove(toast);
  }
}
