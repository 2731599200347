import { Injectable, OnDestroy } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { BehaviorSubject, of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ProgressOverlayComponent } from '../progress-overlay/progress-overlay.component';

/**
 * A generic service for displaying a progress overlay.
 */
export interface IProgressOverlayService {

  /**
   * Start the progress overlay.
   *
   * @param title The title of the progress overlay.
   * @param totalProgress The total amount of steps in the progress processes.
   */
  start(title: string, totalProgress: number): void;

  /**
   * Update the progress step.
   *
   * @param currentProgress The current step in the progression.
   */
  progressUpdate(currentProgress: number): void;

  /**
   * Stop the progress overlay.
   */
  stop(): void;
}

/**
 * A service to provide a Progress Overlay.
 */
@Injectable({
  providedIn: 'root'
})
export class ProgressOverlayService implements IProgressOverlayService, OnDestroy {

  /**
   * A stream that emits when the service is being destroyed.
   */
  private readonly destroying$ = new Subject<void>();

  /**
   * Creates an instance of progress overlay service.
   *
   * @param modalService The {@link NgbModal} to use.
   */
  constructor(private readonly modalService: NgbModal) { }

  /**
   * Emits a value to indicate if the progess overlay should be displayed.
   */
  private showProgressValue$ = new BehaviorSubject<boolean>(null);

  /**
   * The title of the progress overlay.
   */
  private title: string;

  /**
   * The total amount of steps in the progress processes.
   */
  private totalProgress: number;

  /**
   * A stream to indicate if the progess overlay should be displayed.
   */
  public readonly showProgress$ = this.showProgressValue$.asObservable();

  /**
   * A reference to the modal overlay.
   */
  private modalReference: NgbModalRef;

  /**
   * Monitor changes to showProgress obervale and toggles the  progress overlay.
   */
  private readonly monitor = this.showProgress$.pipe(
    distinctUntilChanged((prev, curr) => prev === curr),
    takeUntil(this.destroying$),
  ).subscribe({
    next: isLoading => {
      if (isLoading) {
        this.modalReference = this.modalService.open(ProgressOverlayComponent, { backdrop: 'static' });
        (this.modalReference.componentInstance as ProgressOverlayComponent).totalProgress = this.totalProgress;
        (this.modalReference.componentInstance as ProgressOverlayComponent).title = this.title;
      } else if (this.modalReference != null) {
        this.modalReference.close();
      }
    }
  });

  /**
   * Performs clean up.
   */
  public ngOnDestroy(): void {
    this.showProgressValue$.complete();
    this.destroying$.next();
    this.destroying$.complete();
  }

  /**
   * @inheritdoc
   */
  public start(title: string, totalProgress: number): void {
    this.title = title;
    this.totalProgress = totalProgress;
    this.showProgressValue$.next(true);
  }

  /**
   * @inheritdoc
   */
  public progressUpdate(currentProgress: number): void {
    if (this.modalReference != null) {
      (this.modalReference.componentInstance as ProgressOverlayComponent).currentProgress = currentProgress;
    }
  }

  /**
   * @inheritdoc
   */
  public stop(): void {
    of([]).pipe(delay(1000))
      .subscribe({
        next: () => this.showProgressValue$.next(false)
      });
  }
}
