import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

/**
 * Service that provides uris to access the Api resources.
 */
export interface IApiService {
  /**
   * A dictionary of observables emitting api uris.
   */
  api$: { [key: string]: Observable<string> };
}

/**
 * A service providing uri to access the api.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService implements IApiService {
  /**
   * A dictionary of observables emitting api uris.
   */
  public readonly api$: { [key: string]: Observable<string> } = {
    searchClerk: of(environment.apis.searchClerk).pipe(map(uri => this.addHost(uri))),
    pendingSearches: of(environment.apis.pendingSearches).pipe(map(uri => this.addHost(uri))),
    searchGroups: of(environment.apis.searchGroups).pipe(map(uri => this.addHost(uri))),
    appointments: of(environment.apis.appointments).pipe(map(uri => this.addHost(uri))),
    authorities: of(environment.apis.authoritiesGroups).pipe(map(uri => this.addHost(uri))),
    searchClerkGroups: of(environment.apis.searchClerkGroups).pipe(map(uri => this.addHost(uri))),
    pendingSearchesStats: of(environment.apis.pendingSearchesStats).pipe(map(uri => this.addHost(uri))),
    holidays: of(environment.apis.holidays).pipe(map(uri => this.addHost(uri))),
    currentUser: of(environment.apis.currentUser).pipe(map(uri => this.addHost(uri))),
    categories: of(environment.apis.categories).pipe(map(uri => this.addHost(uri))),
  };

  /**
   * Add the Host to a relative URI.
   *
   * @param uri The relative URI.
   * @returns The absolute URI.
   */
  private addHost(uri: string): string {
    const combinedUri = new URL(uri, location.href);
    return combinedUri.href;
  }
}
