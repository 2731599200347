import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbModalModule,
  NgbProgressbarModule,
  NgbTimepickerModule,
  NgbToastModule
} from '@ng-bootstrap/ng-bootstrap';

import { NgSelectModule } from '@ng-select/ng-select';

import { AreaSelectorComponent } from './area-selector/area-selector.component';
import { CompositeInputComponent, DateInputComponent, TimeInputComponent } from './components';
import { DateParserFormatter } from './date-parser-formatter';
import { MetresToMilesPipe } from './metres-to-miles.pipe';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { ProgressOverlayComponent } from './progress-overlay/progress-overlay.component';
import { ToastComponent } from './toast/toast.component';
import { TrustedHtmlPipe } from './trusted-html/trusted-html.pipe';

/**
 * A Module to hold generic shared components.
 */
@NgModule({
  declarations: [
    CompositeInputComponent,
    MetresToMilesPipe,
    DateInputComponent,
    TimeInputComponent,
    AreaSelectorComponent,
    TrustedHtmlPipe,
    ToastComponent,
    ProgressOverlayComponent,
    NotificationModalComponent,
  ],
  exports: [
    CompositeInputComponent,
    MetresToMilesPipe,
    DateInputComponent,
    TimeInputComponent,
    AreaSelectorComponent,
    TrustedHtmlPipe,
    ToastComponent,
    ProgressOverlayComponent,
    NotificationModalComponent,
  ],
  imports: [
    CommonModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbToastModule,
    NgbModalModule,
    NgbProgressbarModule,
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
  ]
})
export class SharedModule { }
