import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ParamMap, ActivatedRouteSnapshot } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, startWith, distinctUntilChanged } from 'rxjs/operators';

/**
 * A service to monitor the router for changes.
 */
export interface IRouteMonitorService {

  /**
   * A stream that emits when the application parameters change.
   */
  readonly applicationParams$: Observable<{ [key: string]: string | string[] }>;

  /**
   * Get a stream that emits when the named parameter string changes.
   */
  getParameter$(name: string): Observable<string>;

  /**
   * Get a stream that emits when the named parameter array changes.
   */
  getParameterArray$(name: string): Observable<string[]>;
}

/**
 * A service to monitor the router for changes.
 */
@Injectable({
  providedIn: 'root'
})
export class RouteMonitorService implements IRouteMonitorService {

  /**
   * A stream that emits when a route change is complete.
   */
  private readonly navigated$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith({})
  );

  /**
   * A stream that emits when the query parameters change.
   */
  private readonly queryParams$ = this.navigated$.pipe(
    map(() => this.extractQueryParameters(this.router.routerState.snapshot.root.queryParamMap))
  );

  /**
   * A stream that emits when the route parameters change.
   */
  private readonly routeParams$ = this.navigated$.pipe(
    map(() => this.extractRouteParameters(this.router.routerState.snapshot.root))
  );

  /**
   * A stream that emits when the application parameters change.
   */
  public readonly applicationParams$ = combineLatest([
    this.queryParams$,
    this.routeParams$
  ]).pipe(
    map(([queryParams, routeParams]) => ({ ...queryParams, ...routeParams })),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * Creates an instance of route monitor service.
   *
   * @param router The {@link Router} instance that we wil monitor.
   */
  constructor(private readonly router: Router) { }

  /**
   * Get a stream that emits when the named parameter string changes.
   *
   * @param name The name of the parameter.
   * @returns A stream that emits the value of the parameter.
   */
  public getParameter$(name: string): Observable<string> {
    return this.applicationParams$.pipe(
      map(params => params[name]),
      filter(param => param !== undefined && param !== null),
      map(param => param instanceof Array ? param[0] : param),
      distinctUntilChanged((a, b) => a === b),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * Get a stream that emits when the named parameter array changes.
   *
   * @param name The name of the parameter.
   * @returns A stream that emits the value of the parameter.
   */
  public getParameterArray$(name: string): Observable<string[]> {
    return this.applicationParams$.pipe(
      map(params => params[name]),
      filter(param => param !== undefined && param !== null),
      map(param => param instanceof Array ? param : [param]),
      distinctUntilChanged((a, b) => a.reduce((result, value, index) => result && value === b[index], a.length === b.length)),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * Reformats a query parameter map as an object.
   *
   * @param params The {@link ParamMap} to process.
   * @returns An object containing the query parameters.
   */
  private extractQueryParameters(params: ParamMap): { [key: string]: string | string[] } {
    return params.keys.map(
      key => ({ key, value: params.getAll(key) })
    ).reduce(
      (result, keyValuePair) => ({
        ...result,
        [keyValuePair.key]: keyValuePair.value.length === 1 ? keyValuePair.value[0] : keyValuePair.value
      }),
      {} as { [key: string]: string | string[] }
    );
  }

  /**
   * Combines all the parameter maps from the supplied route and it's children
   * and reformats them as a single object.
   *
   * @param route The route to process.
   * @returns An object containing the query parameters.
   */
  private extractRouteParameters(route: ActivatedRouteSnapshot): { [key: string]: string } {
    return this.extractParams(route).reduce(
      (result, keyValuePair) => ({
        ...result,
        [keyValuePair.key]: keyValuePair.value
      }),
      {} as { [key: string]: string }
    );
  }

  /**
   * Recursively extracts parameters from a route as an array of key/value pairs.
   *
   * @param route The route to process.
   * @returns The combined array of parameters.
   */
  private extractParams(route: ActivatedRouteSnapshot): { key: string; value: string }[] {
    return [
      ...[].concat(...route.children.map(child => this.extractParams(child))),
      ...route.paramMap.keys.map(key => ({ key, value: route.paramMap.get(key) }))
    ];
  }
}
