import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

/**
 * The Data Store Module.
 */
@NgModule({
  imports: [
    CommonModule
  ]
})
export class DataStoreModule { }
