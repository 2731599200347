import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from 'src/app/shared';

import { AuthorityDepartmentSelectorComponent } from './authority-department-selector/authority-department-selector.component';

/**
 * The Local Authority Module.
 */
@NgModule({
  declarations: [
    AuthorityDepartmentSelectorComponent
  ],
  exports: [
    AuthorityDepartmentSelectorComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class LocalAuthorityModule { }
