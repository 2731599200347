import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IPublicClientApplication, PublicClientApplication, LogLevel } from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration
} from '@azure/msal-angular';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication';
import { ChatterMessageType, ChatterModule, ChatterService } from './chatter';
import { HttpModule } from './http';
import { LocalAuthorityModule } from './local-authority';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { RoutingModule } from './routing';
import { SharedModule } from './shared';
import { SystemModule } from './system';

/**
 * The application root module.
 */
@NgModule({
  declarations: [
    AppComponent,
    LoggedOutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    HttpModule,
    ChatterModule,
    SystemModule,
    SharedModule,
    AuthenticationModule,
    LocalAuthorityModule,
    RoutingModule,
    NgbDropdownModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: (): IPublicClientApplication => new PublicClientApplication({
        ...environment.msalConfig,
        system: {
          ...environment.msalConfig.system,
          loggerOptions: {
            ...environment.msalConfig.system.loggerOptions,
            loggerCallback: (logLevel: LogLevel, message: string): void => {
              switch (logLevel) {
                case LogLevel.Error:
                  console.error(message);
                  break;
                case LogLevel.Warning:
                  console.warn(message);
                  break;
                default:
                  console.log(message);
                  break;
              }
            }
          }
        }
      })
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: (): MsalGuardConfiguration => environment.msalGuardConfig
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: (): MsalInterceptorConfiguration => environment.msalInterceptorConfig
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  /**
   * Creates an instance of app module.
   *
   * @param chatterService The {@link  ChatterService} instance to use.
   *
   * We register a message handler here for each chatter message type that
   * we're not interested in to suppress warnings in the JavaScript Console.
   */
  constructor(chatterService: ChatterService) {
    chatterService.addListener(ChatterMessageType.searchOpened, () => { });
    chatterService.addListener(ChatterMessageType.searchClosed, () => { });
    chatterService.addListener(ChatterMessageType.searchUpdated, () => { });
    chatterService.addListener(ChatterMessageType.searchCreated, () => { });
    chatterService.addListener(ChatterMessageType.hartsMessage, () => { });
  }
}
