import { Component, Input, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * A component that displays a progress bar as an overlay.
 */
@Component({
  selector: 'fw-progress-overlay',
  templateUrl: './progress-overlay.component.html',
  styleUrls: ['./progress-overlay.component.scss']
})
export class ProgressOverlayComponent {

  /**
   * Creates an instance of area progress overlay component.
   *
   * @param activeModal The {@link NgbActiveModal} instance to use.
   */
  constructor(public activeModal: NgbActiveModal) { }

  /**
   * The title of the progress overlay.
   */
  @Input() public title: string;

  /**
   * The amount of progress steps.
   */
  @Input() public totalProgress: number;

  /**
   * The current step of progress.
   */
  @Input() public currentProgress: number;
}
