import { Injectable } from '@angular/core';

import { HubConnection, HubConnectionState } from '@microsoft/signalr';

import { from, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ObjectDateParserService } from 'src/app/shared';

import { ChatterMessageType } from './chatter-message-type';
import { ConnectionFactoryService } from './connection-factory.service';

/**
 * A service to wrap the WebSocket connection to the server.
 */
export interface IChatterService {

  /**
   * Gets a values detetmining whether the connection is open or not.
   */
  readonly isConnectionOpen: boolean;

  /**
   * Gets the ID of the connection to the server.
   */
  readonly hubConnectionId: string;

  /**
   * Gets a stream that emits whenever the service connects or reconnects to the server.
   */
  readonly connectionOpened$: Observable<void>;

  /**
   * Adds a listener to be called whenever a particular type of message is recieved.
   *
   * @template T The type of the payload of the message.
   * @param type The message type to listen for.
   * @param callback The listener to be called when the message is recieved.
   */
  addListener<T>(type: ChatterMessageType, callback: (payload: T) => void): void;

  /**
   * Removes all listeners for a message type.
   *
   * @param type The message type to remove the listeners for.
   */
  removeListener(type: ChatterMessageType): void;

  /**
   * Sends a message to the server.
   *
   * @template T The type of the message payload.
   * @param type The message type to send.
   * @param payload The payload to send with the message.
   */
  send<T>(type: ChatterMessageType, payload: T): Observable<T>;

}

/**
 * A service to wrap the WebSocket connection to the server.
 */
@Injectable({
  providedIn: 'root'
})
export class ChatterService implements IChatterService {

  /**
   * The connection to the server.
   */
  private hubConnection: HubConnection;

  /**
   * The connection ID of the current connection.
   */
  private connectionId: string;

  /**
   * A stream that emits whenever the service connects or reconnects to the server.
   */
  private readonly chatterConnected$ = new Subject<void>();

  /**
   * Determines whether the service is in the process of connecting.
   */
  private isConnecting = false;

  /**
   * Creates an instance of chatter service.
   *
   * @param connectionFactory The {@link ConnectionFactoryService} instance to use.
   * @param dateParserService The {@link ObjectDateParserService} instance to use.
   */
  constructor(
    private readonly connectionFactory: ConnectionFactoryService,
    private readonly dateParserService: ObjectDateParserService
  ) { }

  /**
   * Gets a values determining whether the connection is open or not.
   */
  public get isConnectionOpen(): boolean {
    return this.hubConnection && this.hubConnection.state === HubConnectionState.Connected;
  }

  /**
   * Gets the ID of the connection to the server.
   */
  public get hubConnectionId(): string {
    return this.hubConnection && this.connectionId;
  }

  /**
   * Gets a stream that emits whenever the service connects or reconnects to the server.
   */
  public get connectionOpened$(): Observable<void> {
    return this.chatterConnected$;
  }

  /**
   * Adds a listener to be called whenever a particular type of message is recieved.
   *
   * @template T The type of the payload of the message.
   * @param type The message type to listen for.
   * @param callback The listener to be called when the message is recieved.
   */
  public addListener<T>(
    type: ChatterMessageType,
    callback: (payload: T) => void
  ): void {
    this.ensureConnected().then(
      () => this.hubConnection.on(
        type,
        payload => {
          const data = payload;
          this.dateParserService.convertDates(data);
          callback(data);
        }
      )
    ).catch(() => { });
  }

  /**
   * Removes all listeners for a message type.
   *
   * @param type The message type to remove the listeners for.
   */
  public removeListener(type: ChatterMessageType): void {
    this.ensureConnected().then(
      () => this.hubConnection.off(type)
    ).catch(() => { });
  }

  /**
   * Sends a message to the server.
   *
   * @template T The type of the message payload.
   * @param type The message type to send.
   * @param payload The payload to send with the message.
   * @returns A pipeline that emits when the message has been sent.
   */
  public send<T>(
    type: ChatterMessageType,
    payload: T
  ): Observable<T> {
    return from(this.ensureConnected()).pipe(
      switchMap(() => this.hubConnection.invoke(type, payload))
    );
  }

  /**
   * Ensures that the application is connected to the server.
   *
   * @returns A promise that resolves when the connection is made.
   */
  private async ensureConnected(): Promise<void> {
    if (!this.isConnectionOpen && !this.isConnecting) {
      this.isConnecting = true;
      this.hubConnection = this.connectionFactory.getHubConnection();

      this.hubConnection.onclose(
        async () => setTimeout(() => this.start(), 5000)
      );

      await this.start();

      this.isConnecting = false;
      this.hubConnection.invoke(ChatterMessageType.getConnectionId).then(
        connectionId => this.connectionId = connectionId
      );
    }
  }

  /**
   * Start or restart the connection to the server.
   */
  private async start(): Promise<void> {
    try {
      await this.hubConnection.start();
      if (this.isConnectionOpen) {
        this.chatterConnected$.next();
      }
    } catch (err) {
      setTimeout(() => this.start(), 5000);
    }
  }
}
