import { Component } from '@angular/core';

/**
 * A Component to display when the User is Logged Out.
 */
@Component({
  selector: 'fw-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss']
})

/**
 * The component displayed when the user logs out of the application.
 */
export class LoggedOutComponent { }
