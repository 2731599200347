import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ChatterSessionInterceptor } from './chatter-session-interceptor';

/**
 * The Chatter Module.
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ChatterSessionInterceptor, multi: true }
  ]
})
export class ChatterModule { }
