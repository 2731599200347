<div class="modal-wrapper">
  <div *ngIf="title" class="modal-header">
    <div class="modal-title">
      <span>{{title}}</span>
    </div>
  </div>
  <div class="modal-body">
    <ngb-progressbar *ngIf="totalProgress > 0; else loading;"
                     type="info"
                     [animated]="true"
                     [value]="(100 / totalProgress) * currentProgress">
    </ngb-progressbar>
    <ng-template #loading>
      <div class="spinner">
        <span class="spinner-border"
              role="status"
              aria-hidden="true"></span>
      </div>
    </ng-template>
  </div>
</div>
