import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ObjectDateParserService } from 'src/app/shared';

/**
 * An HTTP Interceptor to convert date strings on the body of an HTTP response to date objects.
 */
@Injectable()
export class DateConverterInterceptor implements HttpInterceptor {

  /**
   * Creates an instance of date converter interceptor.
   *
   * @param dateParserService The {@link ObjectDateParserService} to use.
   */
  constructor(private readonly dateParserService: ObjectDateParserService) { }

  /**
   * @inheritdoc
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.dateParserService.convertDates(event.body);
        }
      }));
  }
}
