import { inject, NgModule } from '@angular/core';
import { CanActivateFn, Router, RouterModule, Routes } from '@angular/router';
import { MsalBroadcastService, MsalGuard, MsalService } from '@azure/msal-angular';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map, take } from 'rxjs';

/**
 * A {@link CanActivateFn} to check the User is a HARTs User.
 *
 * @returns A value indicating that the user is a HARTs user or a URL Tree to redirect to.
 */
const hartsUserGuard: CanActivateFn = () => {
  const msal = inject(MsalService);
  const router = inject(Router);

  return inject(MsalBroadcastService).inProgress$.pipe(
    filter(status => status === InteractionStatus.None),
    map(() => {
      const userRoles = msal.instance.getAllAccounts()[0]?.idTokenClaims?.roles ?? [];

      return ['RlasUsers', 'RdwsUsers'].some(role => userRoles.includes(role)) ||
        router.createUrlTree(['/logged-out']);
    }),
    take(1)
  );
};

/**
 * The Application Routes.
 */
const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/schedule',
    pathMatch: 'full',
    title: 'forWard by InfoTrack'
  },
  {
    path: 'logged-out',
    component: LoggedOutComponent,
    title: 'forWard by InfoTrack'
  },
  {
    canActivate: [MsalGuard, hartsUserGuard],
    path: 'auth',
    title: 'forWard by InfoTrack',
    loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule)
  }
];

/**
 * The Application Routing Module.
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
