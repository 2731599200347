import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, Configuration, InteractionType, LogLevel } from '@azure/msal-browser';

const isStagingEnvironment = window.location.origin.includes('staging');

/**
 * See below for configuration information:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular#config-options-for-msal-initialization.
 */
const msalConfig: Configuration = {
  auth: {
    authority: 'https://login.microsoftonline.com/d6bb6b53-5c8d-4396-9886-ad5970a41ad2',
    clientId: 'fdcc53a6-655e-486e-a038-931708003a3a',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: `${window.location.origin}/logged-out`
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage
  },
  system: {
    navigateFrameWait: 10,
    loggerOptions: {
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false
    }
  }
};

const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Popup,
  protectedResourceMap: new Map<string, string[]>([
    ['https://forward.infotrack.co.uk/api', ['api://fdcc53a6-655e-486e-a038-931708003a3a/access_as_user']],
    ['https://staging-forward.infotrack.co.uk/api', ['api://fdcc53a6-655e-486e-a038-931708003a3a/access_as_user']]
  ]),
};

const msalGuardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: ['api://fdcc53a6-655e-486e-a038-931708003a3a/access_as_user'],
    redirectUri: window.location.origin
  },
  loginFailedRoute: '/logged-out'
};

const searchClerkApi = '/api/v4.0/search-clerks';
const pendingSearchesApi = '/api/v4.0/pending-searches';
const searchGroupsApi = '/api/v4.0/authorities/groups';
const authoritiesApi = '/api/v4.0/authorities';
const appointmentsApi = '/api/v4.0/appointments';
const holidaysApi = '/api/v4.0/system-settings/holidays';
const usersApi = '/api/v4.0/users';
const currentUserApi = `${usersApi}/me`;
const searchClerkGroupsApi = `${searchClerkApi}/groups`;
const pendingSearchesStatsApi = `${pendingSearchesApi}/count`;
const searchAnalystMapUri = `https://${isStagingEnvironment ? 'staging-' : ''}searchanalystmap.infotrack.co.uk`;
const categoriesApi = '/api/v4.0/categories';

export const environment = {
  production: true,
  mockApis: false,
  apis: {
    searchClerk: searchClerkApi,
    pendingSearches: pendingSearchesApi,
    searchGroups: searchGroupsApi,
    authoritiesGroups: authoritiesApi,
    appointments: appointmentsApi,
    searchClerkGroups: searchClerkGroupsApi,
    pendingSearchesStats: pendingSearchesStatsApi,
    holidays: holidaysApi,
    currentUser: currentUserApi,
    categories: categoriesApi
  },
  gcInterval: 600,
  dataStoreLogging: false,
  chatterUri: '/api/v2.0/chatter',
  emailAddresses: {
    dataResearch: 'Data.Research@infotrack.co.uk'
  },
  searchAnalystMapUri,
  msalConfig,
  msalInterceptorConfig,
  msalGuardConfig
};
